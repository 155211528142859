<template>
  <div class="yh_wrapper">
    <template v-for="(item ,ind) in datas">
      <div class="yh_list" :key="item.id + 'inde' + ind">
        <header>
          <p>发表时间：{{getTime(item.createTime)}}</p>
          <el-rate
            v-model="item.score"
            disabled
            text-color="#ff9900"
          >
          </el-rate>
        </header>
        <main class="yh_main">
          <div class="yh_main_wra">
            {{item.content}}
            <p>源自：{{item.creatorName}}</p>
          </div>
          <div class="yh_btn bg_blue" @click="viewDetail(item)">
            查看课程详情
          </div>
        </main>
      </div>
    </template>
    <pagination class="pagination jumpBtn" v-if="total > 0" :pageSize="formData.pageSize" :total="total" @pageChange="pageChange" @sizeChange="sizeChange" />
  </div>
</template>

<script>
import API from '@/apis/studentsEndAPI/personalCenterAPI'
import liveAPI from '@/apis/studentsEndAPI/liveMenuDetail'
import commonUtils from '@/utils'
import { getTime } from '@/assets/js/format.js'
import { EventBus } from '@/assets/js/eventBus';
import Pagination from '@comps/backStageComponent/pagination';
export default {
  name: 'evaluate',
  components: {
    Pagination,
  },
  data() {
    return {
      value: 3.7,
      total: '',
      datas: [],
      formData: {
        page: 1,
        pageSize: 6,
        creatorId: '',
      },

    }
  },
  mounted() {
    if(sessionStorage.getItem('profile')) {
      this.formData.creatorId = JSON.parse(sessionStorage.getItem('profile')).userId
    }
    this.getEvaluation()
  },
  methods: {
    getTime,
    async getEvaluation() {
      let data = await API.getEvaluationList(commonUtils.parseToParams(this.formData))
      if(data && !data.status) {
        this.datas = data.dataList
        this.total = data.rowCount
        
      }
    },
    async viewDetail(val) {
      let id = val.itemId
      let code = ''
      let data = await liveAPI.course(id,code)
      if(data && !data.status) {
        if(data.courseType == 1) {//1|直播课，2|章节录播课
          this.$router.push({
            path: '/liveDetail',
            query: {id: data.courseId}
          })
        }else {
          this.$router.push({
            path: '/dibbleDetail',
            query: {id: data.courseId}
          })
        }
      }
    },
    pageChange(val) {
      this.formData.page = val
      this.getEvaluation()
    },
    sizeChange(val) {
      this.formData.pageSize = val
      this.getEvaluation()
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/common.scss";
.yh_wrapper {
	padding: 0 30px;
	width: 100%;
	.yh_list {
		// padding: 10px;
		margin-bottom: 18px;
		header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 30px 0 15px;
			// width: 942px;
			height: 42px;
			background: #f7f7f7;
			p {
				color: #333;
				font-size: 12px;
				line-height: 17px;
			}
			::v-deep {
				.el-rate__icon {
					font-size: 22px;
				}
			}
		}
		main {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;
			background: #fff;
			.yh_main_wra {
				display: inline-block;
				padding: 22px 5px;
				width: 80%;
				color: #333;
				text-align: justify;
				text-justify: distribute-all-lines;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;

				-webkit-text-align: justify;
				// text-align-last: justify;
				p {
					margin-top: 15px;
					color: #91929e;
					line-height: 20px;
				}
			}
			.yh_btn {
				margin-right: 30px;
				width: 108px;
				height: 30px;
				border-radius: 5px;
				color: #fff;
				text-align: center;
				font-weight: bold;
				font-size: 13px;
				line-height: 30px;
				cursor: pointer;
			}
		}
	}
}
</style>
